<script>
    export let clickable = false;

    let className = '';
    export { className as class };
    export let p_xl = false;
</script>

<div
    class="
        {clickable ? 'cursor-pointer hover:bg-surface-default focus-within:ring-4 focus-within:ring-blue-500' :''}
        w-full flex-grow-1
        bg-surface-muted border border-border-default rounded-lg overflow-hidden
        {p_xl ? 'p-8 md:p-16' : 'p-4'} transition-colors {className}"
    on:click
    on:keydown={e => e.key === 'Enter' && e.currentTarget.click()}
    role={clickable ? 'button' : undefined}
>
    <slot />
</div>
